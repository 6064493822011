'use client';

import { canRoleEdit, canRoleEditSection } from '@knapsack/core';
import type { AppSection, Role } from '@knapsack/types';
import { useKsLocation } from '@/utils/ks-location.hooks';
import {
  AppContext,
  useAppCtxSelector,
  useAppStateMatches,
  useUiCtxSelector,
} from './machines/app/app.xstate';
import { extractPossibleInstanceIdFromSite } from '../env-and-content-src/utils';

export function useUserRole(): Role {
  return useAppCtxSelector(({ site, user }) => {
    return user?.getSiteRole(site?.meta.siteId) ?? 'ANONYMOUS';
  });
}

export function useUserRoleCanEdit(): boolean {
  return useAppCtxSelector(({ site, user }) => {
    return canRoleEdit(user?.getSiteRole(site?.meta.siteId));
  });
}

export function useUserRoleCanEditSection(section: AppSection): boolean {
  const userRole = useUserRole();
  return canRoleEditSection({
    section,
    userRole,
  });
}

/**
 * Ensures that the site is in an editable state and the user
 * can edit the current section returned from {@link useKsLocation}
 */
export function useCanEdit(): boolean {
  const siteIsEditable = useAppStateMatches(
    'site.loaded.appClient.viewable.editable',
  );
  const { section } = useKsLocation();
  const userRoleCanEditSection = useUserRoleCanEditSection(section);

  return siteIsEditable && userRoleCanEditSection;
}

/**
 * Checks if App Client is connected locally by one of two ways:
 * 1. `site.type === 'local'` - the classic local dev mode
 * 2. If the "Env" is "development" - i.e. the App Client URL is localhost
 */
export function useIsEnvDev(): boolean {
  return useAppCtxSelector(({ site }) => site?.env?.type === 'development');
}

export function useIsSitePrivate(): boolean {
  return useAppCtxSelector(({ site }) => site?.meta.isPrivate);
}

export function useInstanceId(): string | null {
  return useAppCtxSelector((ctx) =>
    extractPossibleInstanceIdFromSite(ctx.site),
  );
}

export function useInstanceStatus() {
  return useAppCtxSelector(({ site }) =>
    site?.contentSrc.type === 'cloud-authoring' &&
    site.contentSrc.instance.type === 'branch'
      ? site.contentSrc.instance.instanceStatus
      : null,
  );
}

/**
 * Must use this hook in a cloud-authoring context.
 * `site.contentSrc.type === 'cloud-authoring'`
 */
export function useIsLatestInstance() {
  return useAppCtxSelector(({ site }) => {
    if (!site) return false;
    if (site.contentSrc.type === 'current-env-server') {
      throw new Error(
        'Cannot use "useIsLatestInstance" in when "site.contentSrc.type" is "current-env-server"',
      );
    }
    return site.contentSrc.instance.type === 'latest';
  });
}

/**
 * Simplified check for winWidthName to not have to check
 * for large and xlarge.
 */
export function useIsLargeScreen(): boolean {
  return useUiCtxSelector(({ winWidthName }) => {
    return winWidthName === 'large' || winWidthName === 'xlarge';
  });
}

/**
 * Simplified check for winWidthName to not have to check
 * for xsmall and small
 */
export function useIsSmallScreen(): boolean {
  return useUiCtxSelector(({ winWidthName }) => {
    return winWidthName === 'xsmall' || winWidthName === 'small';
  });
}

/**
 * Grab a single feature flag. Example:
 * ```js
 * const enableWorldPeace = useFeatureFlag('enableWorldPeace');
 * ```
 */
export function useFeatureFlag<Flag extends keyof AppContext['featureFlags']>(
  flag: Flag,
  // generic ensures return type is accurate for what flag we are grabbing
): AppContext['featureFlags'][Flag] {
  return useAppCtxSelector((ctx) => ctx.featureFlags[flag]);
}
