import type { AppSection } from '@knapsack/types';
import { BASE_PATHS, HOMEPAGE_PAGE_ID, SECTION_PATH_BASES } from './constants';

export type KsLocation =
  | {
      section: Extract<AppSection, 'SETTINGS'>;
      type: 'settings';
      settingsId: string;
    }
  | {
      section: Extract<AppSection, 'DOCUMENT'>;
      type: 'page';
      pageId: string;
      isHomepage?: boolean;
    }
  | {
      section: Extract<AppSection, 'DOCUMENT'>;
      type: 'pattern';
      patternId: string;
      tab:
        | {
            type: 'subPage';
            subPageId: string;
          }
        | {
            type: 'template';
            templateId: string;
            demoId: string;
          }
        | {
            type: 'designSrc';
            designSrcId: string;
          }
        | {
            type: 'unknown';
          };
    }
  | {
      section: Extract<AppSection, 'DEVELOP'>;
      type: 'patternAdmin';
    }
  | {
      section: Extract<AppSection, 'PROTOTYPE'>;
      type: 'prototypes.list';
      rendererId: string;
      templateId: string;
    }
  | {
      section: Extract<AppSection, 'PROTOTYPE'>;
      type: 'prototypes.item';
      rendererId: string;
      templateId: string;
      demoId: string;
    }
  | {
      section: Extract<AppSection, 'DOCUMENT'>;
      type: 'pluginPage';
      pluginId: string;
      pageId: string;
    }
  | {
      section: Extract<AppSection, 'ROOT'>;
      type: 'share.pattern';
      patternId: string;
    }
  | {
      section: Extract<AppSection, 'DESIGN'>;
      type: 'design.sources';
    }
  | {
      section: Extract<AppSection, 'DESIGN'>;
      type: 'design.tokens';
      groupId?: string;
    }
  | {
      section: Extract<AppSection, 'DESIGN'>;
      type: 'design.variables';
      collectionId?: string;
      collectionGroupId?: string;
    }
  | {
      section: Extract<AppSection, 'ROOT'>;
      type: 'appHome';
    }
  | {
      section: Extract<AppSection, 'ROOT'>;
      type: 'unknown';
    };

export function getKsLocation({
  pathname,
  searchParams = new URLSearchParams(),
}: {
  /**
   * i.e. `/site/123/456/pattern/button`
   * NO query strings - pass those in via `searchParams`
   */
  pathname: string;
  searchParams?: URLSearchParams;
}): KsLocation {
  if (!pathname.startsWith('/')) {
    throw new Error(`pathname must start with /, got ${pathname}`);
  }
  if (pathname.includes('?')) {
    throw new Error(
      `pathname must not include query strings, got ${pathname}, pass those in via searchParams`,
    );
  }

  const paths = pathname.split('/').filter(Boolean);
  if (paths.length === 0) {
    return {
      section: 'ROOT',
      type: 'appHome',
    };
  }
  const [
    /** Most likely `site` */
    _appBase,
    /** Which site we're in - i.e. `ks-demo-bootstrap` */
    _siteId,
    /** Which site instance - i.e. `latest` or `R3vWHdP` */
    instanceId,
    /**
     * Base path for what type; `patterns`
     */
    basePath,
    /**
     * If base path is patterns, this would be patternId
     */
    firstParam,
    secondParam,
    thirdParam,
  ] = paths;
  if (!instanceId) {
    return {
      section: 'ROOT',
      type: 'unknown',
    };
  }

  if (!basePath) {
    return {
      section: 'DOCUMENT',
      type: 'page',
      isHomepage: true,
      pageId: HOMEPAGE_PAGE_ID,
    };
  }

  switch (true) {
    case `/${basePath}` === BASE_PATHS.PAGES:
      return {
        section: 'DOCUMENT',
        type: 'page',
        pageId: firstParam,
      };
    case `/${basePath}` === BASE_PATHS.SETTINGS:
      return {
        section: 'SETTINGS',
        type: 'settings',
        settingsId: firstParam,
      };
    case `/${basePath}` === BASE_PATHS.PLUGINS:
      return {
        section: 'DOCUMENT',
        type: 'pluginPage',
        pluginId: firstParam,
        pageId: secondParam,
      };
    case `/${basePath}/${firstParam}` === '/develop/patterns':
      return {
        section: 'DEVELOP',
        type: 'patternAdmin',
      };
    case `/${basePath}` === SECTION_PATH_BASES.PROTO: {
      if (thirdParam) {
        return {
          section: 'PROTOTYPE',
          type: 'prototypes.item',
          rendererId: firstParam,
          templateId: secondParam,
          demoId: thirdParam,
        };
      }
      return {
        section: 'PROTOTYPE',
        type: 'prototypes.list',
        rendererId: firstParam,
        templateId: secondParam,
      };
    }
    case `/${basePath}/${firstParam}` ===
      `${SECTION_PATH_BASES.DESIGN}/sources`: {
      return {
        section: 'DESIGN',
        type: 'design.sources',
      };
    }
    case `/${basePath}/${firstParam}` === BASE_PATHS.VARIABLES: {
      return {
        section: 'DESIGN',
        type: 'design.variables',
        collectionId: secondParam ? decodeURIComponent(secondParam) : '',
        collectionGroupId: thirdParam ? decodeURIComponent(thirdParam) : '',
      };
    }
    case `/${basePath}/${firstParam}` === BASE_PATHS.TOKENS: {
      return {
        section: 'DESIGN',
        type: 'design.tokens',
        groupId: secondParam ? decodeURIComponent(secondParam) : '',
      };
    }
    case `/${basePath}` === BASE_PATHS.PATTERN: {
      if (searchParams.has('templateId')) {
        return {
          section: 'DOCUMENT',
          type: 'pattern',
          patternId: firstParam,
          tab: {
            type: 'template',
            templateId: searchParams.get('templateId'),
            demoId: searchParams.get('demoId'),
          },
        };
      }
      if (searchParams.has('designSrcId')) {
        return {
          section: 'DOCUMENT',
          type: 'pattern',
          patternId: firstParam,
          tab: {
            type: 'designSrc',
            designSrcId: searchParams.get('designSrcId'),
          },
        };
      }
      if (searchParams.has('subPageId')) {
        return {
          section: 'DOCUMENT',
          type: 'pattern',
          patternId: firstParam,
          tab: {
            type: 'subPage',
            subPageId: searchParams.get('subPageId'),
          },
        };
      }
      return {
        section: 'DOCUMENT',
        type: 'pattern',
        patternId: firstParam,
        tab: {
          type: 'unknown',
        },
      };
    }
    case `/${basePath}` === BASE_PATHS.SHARE_PATTERN:
      return {
        section: 'ROOT',
        type: 'share.pattern',
        patternId: firstParam,
      };
    default: {
      return {
        section: 'ROOT',
        type: 'unknown',
      };
    }
  }
}
