'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';
import loMemo from 'lodash/memoize';
import {
  // eslint-disable-next-line no-restricted-imports
  useParams as useNextParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { parse } from 'url';
import urlJoin from 'url-join';
import {
  AppContext,
  useAppCtxSelector,
  useAppStateMatches,
  useUserRoleCanEditSection,
} from '@/core';
import type { PatternRenderData } from '@knapsack/app-client-api';
import { BlockCollectionLocation, KnapsackNavItemType } from '@knapsack/types';
import {
  isRemoteUrl,
  removeUndefinedProps,
  createQueryParams,
} from '@knapsack/utils';
import {
  NextJsFullPath,
  NextJsSiteRelativePath,
  NextJsParams,
  NextJsSettingId,
} from '@/types';
import dynamic from 'next/dynamic';
import { BASE_PATHS, SECTION_PATH_BASES } from './constants';
import { PatternPageQueryString } from './hooks';
import { SpecIdType } from '../domains/patterns/components/compare-spec';
import { SuspenseAndErrorCatcher } from './error-catcher';
import { useKsLocation } from './ks-location.hooks';

const ErrorPage = dynamic(() => import('../core/pages/error-page'));
const LoginPage = dynamic(
  () => import('@/app/(main)/site/[siteId]/[instanceId]/login-page'),
);

/**
 * The Next.js `useParams` hook - with Type Safety.
 * If you make route changes, you may need to restart the server.
 * https://nextjs.org/docs/app/api-reference/functions/use-params
 * @see {@link NextJsParams}
 */
export const useParams = () => useNextParams() as NextJsParams;

/**
 * Create a root relative demo url to view in a standalone environment w/no Knapsack UI
 * i.e. the link you see when you open a pattern in a new window
 */
export function createDemoUrl({
  patternId,
  templateId,
  assetSetId,
  dataId,
  stateId,
  isInIframe = false,
  wrapHtml = true,
  cacheBuster,
}: PatternRenderData): string {
  return `/api/v1/render?${createQueryParams({
    patternId,
    templateId,
    assetSetId,
    dataId,
    isInIframe,
    stateId,
    wrapHtml,
    cacheBuster,
  })}`;
}

export function createSharePatternUrl({
  patternId,
  templateId,
  assetSetId,
  dataId,
  stateId,
  isInIframe = true,
  wrapHtml = true,
  cacheBuster,
}: PatternRenderData): string {
  return `${BASE_PATHS.SHARE_PATTERN}?${createQueryParams({
    patternId,
    templateId,
    assetSetId,
    dataId,
    isInIframe,
    stateId,
    wrapHtml,
    cacheBuster,
  })}`;
}

const AccessDenied = () => (
  <ErrorPage
    graphic="access-denied"
    title="Access Denied"
    message="You don't have enough permissions to access this page"
    testId="access-denied"
  />
);

/**
 * A wrapper around Route that protects the route from being accessed by anonymous
 * users.
 * @example
 * <LoggedInRoute path="/profile" component={Profile}/>
 * @example
 * <LoggedInRoute path="/profile">
 *   <Profile />
 * </LoggedInRoute>
 */
export const LoggedInRoute = ({ children }: { children: React.ReactNode }) => {
  const isLoggedOut = useAppStateMatches('user.loggedOut');
  const isLoggedIn = useAppStateMatches('user.loggedIn');
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);

  if (isLoggedOut)
    return (
      <SuspenseAndErrorCatcher>
        <LoginPage siteId={siteId} />
      </SuspenseAndErrorCatcher>
    );

  // If user is logged in, render the route
  if (isLoggedIn) {
    return children;
  }

  return null;
};

/**
 * A wrapper around Route that protects the route from being accessed by non-
 * contributor+ roles.
 * @example
 * <RoleCanEditRoute path="/edit" component={EditPage}/>
 * @example
 * <RoleCanEditRoute path="/edit">
 *   <EditPage />
 * </RoleCanEditRoute>
 */
export const RoleCanEditRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { section } = useKsLocation();
  const userRoleCanEditSection = useUserRoleCanEditSection(section);

  return (
    <LoggedInRoute>
      {userRoleCanEditSection ? children : <AccessDenied />}
    </LoggedInRoute>
  );
};

/**
 * A wrapper around Route that ensures it only loads if flag is enabled
 * @example
 * <FeatureFlagRoute path="/sign-up" flag="enableCreateNewSiteExperience">
 *   <SignUp />
 * </FeatureFlagRoute>
 */
export const FeatureFlagRoute = ({
  flag,
  children,
}: {
  children: React.ReactNode;
  flag: keyof AppContext['featureFlags'];
}) => {
  const flagLookup = useAppCtxSelector((ctx) => ctx.featureFlags[flag]);

  return typeof flagLookup === 'boolean' && flagLookup ? (
    children
  ) : (
    <AccessDenied />
  );
};

type PatternLinkParams = PatternPageQueryString & {
  patternId: string;
  hash?: string;
  basePath?: string;
};

export type CompareSpecQueryParams = {
  specIdA?: string;
  specIdTypeA?: SpecIdType;
  specIdB?: string;
  specIdTypeB?: SpecIdType;
};

type DevelopRoute =
  | {
      type: 'patterns';
      patternId?: string;
      templateId?: string;
      demoId?: string;
      designSrcId?: string;
    }
  | ({
      type: 'compare-spec';
      patternId: string;
    } & CompareSpecQueryParams);

/**
 * Create a pattern url to view in Knapsack environment
 */
export function createPatternUrlPart({
  patternId,
  templateId,
  demoId,
  subPageId,
  customTabId,
  designSrcId,
  hash = '',
  basePath = BASE_PATHS.PATTERN,
}: PatternLinkParams): string {
  if ([templateId, subPageId, customTabId].filter(Boolean).length > 1) {
    const msg = `Cannot call "createPatternUrl()" with that many params - see console`;
    console.error(msg, { templateId, subPageId, customTabId });
    throw new Error(msg);
  }
  let query: { [key: string]: string };
  if (templateId && demoId) {
    query = {
      templateId,
      demoId,
    };
  } else if (templateId) {
    query = {
      templateId,
    };
  } else if (subPageId) {
    query = {
      subPageId,
    };
  } else if (customTabId) {
    query = {
      customTabId,
    };
  } else if (designSrcId) {
    query = { designSrcId };
  }

  return `${basePath}/${patternId}${
    query
      ? `?${new URLSearchParams(removeUndefinedProps(query)).toString()}`
      : ''
  }${
    !!hash && !hash.startsWith('#')
      ? `#${hash}`
      : // if hash is empty this will be empty string,
        // if it's not empty, it will already have `#` at start
        hash
  }`;
}

export function useLinkBasePath() {
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  const pathname = usePathname();
  if (!siteId) return '/';
  // changes `/site/A/B/C/D` to `/site/A/B`
  return pathname.split('/').slice(0, 4).join('/');
}

export function useKsRouter() {
  const router = useRouter();
  const linkBasePath = useLinkBasePath();
  return useMemo(() => {
    const createPath = (path: NextJsSiteRelativePath) =>
      urlJoin(linkBasePath, path) as NextJsFullPath;
    return {
      createPath,
      router,
      /**
       * Try to use `<Link>` first!!
       * Use this only if you must
       * ```ts
       * import Link from 'next/link';
       * const { createPath } = useKsRouter();
       * <Link href={createPath(path)} />
       * ```
       */
      goTo: (
        path: NextJsSiteRelativePath,
        opt: {
          skipScroll?: boolean;
          replace?: boolean;
        } = {},
      ) => {
        const fullPath = createPath(path);
        if (opt?.replace) {
          router.replace(fullPath, { scroll: !opt?.skipScroll });
        } else {
          router.push(fullPath, { scroll: !opt?.skipScroll });
        }
      },
      /**
       * Try to use `<Link>` first!!
       * Use this only if you must
       * ```ts
       * import Link from 'next/link';
       * const { createPath } = useKsRouter();
       * <Link href={createPath(path)} />
       * ```
       */
      goToFullPath: (
        path: NextJsFullPath,
        opt: {
          skipScroll?: boolean;
          replace?: boolean;
        } = {},
      ) => {
        if (opt?.replace) {
          router.replace(path, { scroll: !opt?.skipScroll });
        } else {
          router.push(path, { scroll: !opt?.skipScroll });
        }
      },
    };
  }, [router, linkBasePath]);
}

export function useKsNav() {
  const history = useRouter();
  const linkBasePath = useLinkBasePath();
  const isSiteLoaded = useAppStateMatches('site.loaded');

  const join = useCallback(
    (path: string): string => {
      if (!linkBasePath) return path;
      if (path?.startsWith(linkBasePath)) return path;
      return urlJoin(linkBasePath, path);
    },
    [linkBasePath],
  );

  const createPatternUrl = useCallback(
    (p: PatternLinkParams): string => {
      const patternPath = createPatternUrlPart(p);
      const path = join(patternPath);
      return path;
    },
    [join],
  );

  const createPatternShareUrl = useCallback(
    (p: PatternLinkParams): string => {
      const patternPath = createPatternUrlPart({
        ...p,
        basePath: BASE_PATHS.SHARE_PATTERN,
      });
      const url = new URL(join(patternPath), window.location.origin);
      return url.href;
    },
    [join],
  );

  const createPageUrl = useCallback(
    ({ pageId, hash = '' }: { pageId: string; hash?: string }): string =>
      join(urlJoin(BASE_PATHS.PAGES, pageId, hash)),
    [join],
  );

  const createSettingsUrl = useCallback(
    ({ settingId = '' }: { settingId: NextJsSettingId | '' }): string =>
      join(urlJoin(BASE_PATHS.SETTINGS, settingId)),
    [join],
  );

  const createTokenAdminUrl = useCallback((): string => {
    const path = join(urlJoin(BASE_PATHS.TOKENS));
    return path;
  }, [join]);

  const createDesignSourcesUrl = useCallback(
    (): string => join(urlJoin(SECTION_PATH_BASES.DESIGN, 'sources')),
    [join],
  );

  const createVariablesAdminUrl = useCallback(
    ({
      collectionId = '',
      collectionGroupId = '',
    }: { collectionId?: string; collectionGroupId?: string } = {}): string => {
      const path = join(
        urlJoin(BASE_PATHS.VARIABLES, collectionId, collectionGroupId),
      );
      return path;
    },
    [join],
  );

  const goToVariablesAdmin = useCallback(
    ({
      collectionId = '',
      collectionGroupId = '',
    }: { collectionId?: string; collectionGroupId?: string } = {}) => {
      const path = createVariablesAdminUrl({
        collectionId,
        collectionGroupId,
      });
      history.push(path);
    },
    [createVariablesAdminUrl, history],
  );

  // Use to LINK TO ROUTE
  const createTokenGroupUrl = useCallback(
    ({ id, edit }: { id: string; edit?: boolean }): string => {
      const path = join(urlJoin(BASE_PATHS.TOKENS, encodeURIComponent(id)));
      const url = new URL(path, 'http://not-actually-used.com');
      edit && url.searchParams.set('edit', 'yes');

      // Return just the /path/to/group?edit=yes. Note: join() has already been
      return url.href.replace(url.origin, '');
    },
    [join],
  );

  /** Develop Section POC */
  const createDevelopUrl = useCallback(
    (route: DevelopRoute): string => {
      switch (route.type) {
        case 'patterns': {
          const {
            patternId = '',
            templateId = '',
            demoId = '',
            designSrcId = '',
          } = route;
          return join(
            urlJoin(
              SECTION_PATH_BASES.DEVELOP,
              'patterns',
              patternId,
              templateId,
              demoId,
              designSrcId,
            ),
          );
        }
        case 'compare-spec': {
          const { patternId, specIdA, specIdB, specIdTypeA, specIdTypeB } =
            route;
          const params = new URLSearchParams(
            removeUndefinedProps({
              specIdA,
              specIdB,
              specIdTypeA,
              specIdTypeB,
            }),
          );
          return join(
            urlJoin(
              SECTION_PATH_BASES.DEVELOP,
              'patterns',
              patternId,
              'compare-spec',
              params.size > 0 ? `?${params.toString()}` : '',
            ),
          );
        }
        default: {
          const _exhaustiveCheck: never = route;
        }
      }
    },
    [join],
  );

  const createProtoUrl = useCallback(
    ({
      rendererId,
      templateId,
      demoId,
    }: {
      rendererId: string;
      templateId: string;
      /** with this, go to item, otherwise to list */
      demoId?: string;
    }): string => {
      const path = [
        SECTION_PATH_BASES.PROTO,
        rendererId,
        templateId,
        demoId,
      ].filter(Boolean);
      return join(urlJoin(...path));
    },
    [join],
  );

  const createBlockCollectionUrl = useCallback(
    (bcl: BlockCollectionLocation): string => {
      switch (bcl.type) {
        case 'page': {
          const { pageId } = bcl;
          return createPageUrl({ pageId });
        }
        case 'patternSubPage': {
          const { patternId, subPageId } = bcl;
          return createPatternUrl({
            patternId,
            subPageId,
          });
        }
        case 'patternTemplate': {
          const { patternId, templateId } = bcl;
          return createPatternUrl({
            patternId,
            templateId,
          });
        }
        default: {
          const _exhaustiveCheck: never = bcl;
        }
      }
    },
    [createPageUrl, createPatternUrl],
  );

  return useMemo(
    () => ({
      linkBasePath,
      isSiteInstanceReady: isSiteLoaded && !!linkBasePath,
      goTo: (path: string) => history.push(join(path)), // -> /site/SITE_ID/INSTANCE_ID/x
      goToRoot: (path: string) => history.push(path), // -> /x
      createUrl: (path: string) => join(path),
      createPatternUrl,
      createPatternShareUrl,
      createDevelopUrl,
      goToDevelopUrl: (route: DevelopRoute) =>
        history.push(createDevelopUrl(route)),
      createSettingsUrl,
      createTokenAdminUrl,
      createTokenGroupUrl,
      goToTokenGroupUrl: ({ id, edit }: { id: string; edit?: boolean }) => {
        const path = createTokenGroupUrl({ id, edit });
        return history.replace(path);
      },
      goToPattern: (p: PatternLinkParams) => history.push(createPatternUrl(p)),
      createPageUrl,
      replaceAndGoToPage: ({ pageId }: { pageId: string }) =>
        history.replace(createPageUrl({ pageId })),
      goToPage: ({ pageId }: { pageId: string }) =>
        history.push(createPageUrl({ pageId })),

      goToSetting: ({ settingId }: { settingId: NextJsSettingId }) =>
        history.push(createSettingsUrl({ settingId })),
      createPluginPageUrl: ({
        pluginId,
        pagePath,
      }: {
        pluginId: string;
        pagePath: string;
      }) => join(urlJoin('/plugins', pluginId, pagePath)),
      createBlockCollectionUrl,
      goToBlockCollection: (bcl: BlockCollectionLocation) =>
        history.push(createBlockCollectionUrl(bcl)),
      createVariablesAdminUrl,
      goToVariablesAdmin,
      goToTokenAdmin: () => history.push(createTokenAdminUrl()),
      createProtoUrl,
      createDesignSourcesUrl,
      goToDesignSources: () => history.push(createDesignSourcesUrl()),
    }),
    [
      linkBasePath,
      isSiteLoaded,
      createPatternUrl,
      createPatternShareUrl,
      createDevelopUrl,
      createSettingsUrl,
      createTokenAdminUrl,
      createTokenGroupUrl,
      createPageUrl,
      createBlockCollectionUrl,
      createVariablesAdminUrl,
      goToVariablesAdmin,
      createProtoUrl,
      createDesignSourcesUrl,
      history,
      join,
    ],
  );
}

export function getTypeFromPath(urlPath: string): {
  contentType: KnapsackNavItemType | 'unknown';
  contentId?: string;
} {
  if (!urlPath) {
    return {
      contentType: 'group',
    };
  }

  if (isRemoteUrl(urlPath)) {
    return {
      contentType: 'external',
    };
  }

  // Ensure we have a leading slash
  const path = urlPath.startsWith('/') ? urlPath : `/${urlPath}`;

  // clean up passed in path by removing querystrings
  const { pathname } = parse(path);
  // done cleaning

  const paths = pathname.split('/').filter(Boolean);
  const [
    /** Most likely `site` */
    appBase,
    /** Which site we're in - i.e. `ks-demo-bootstrap` */
    siteId,
    /** Which site instance - i.e. `latest` or `R3vWHdP` */
    instanceId,
    /**
     * Base path for what type; `patterns`
     */
    basePath,
    /**
     * If base path is patterns, this would be patternId
     */
    firstParam,
    /**
     * If base path is patterns, this would be templateId
     */
    secondParam,
    ...restParams
  ] = paths;

  if (appBase !== 'site') {
    // paths can be sent in one of two ways:
    // 1. /site/my-site/latest/pattern/button
    // 2. /pattern/button
    // doing this next line makes number 2 work
    return getTypeFromPath(`/site/ks-fake-site/latest${path}`);
  }

  if (`/${basePath}` === BASE_PATHS.PATTERN) {
    return {
      contentType: 'pattern',
      contentId: firstParam,
    };
  }

  if (`/${basePath}` === BASE_PATHS.PAGES) {
    return {
      contentType: 'page',
      contentId: firstParam,
    };
  }
  return {
    contentType: 'unknown',
  };
}

export const getTypeFromPathMemo = loMemo(getTypeFromPath);

/**
 * Are we currently at a vanilla tokens route? iei at /design/design-tokens
 */
export const useIsTokenAdminRoute = () => {
  const { createTokenAdminUrl } = useKsNav();
  const pathname = usePathname();
  return pathname.startsWith(createTokenAdminUrl());
};

/**
 * Are we currently at a token collections route? i.e. /design/design-token-collections
 */
export const useIsTokenCollectionsRoute = () => {
  const { createVariablesAdminUrl } = useKsNav();
  const pathname = usePathname();
  return pathname.startsWith(createVariablesAdminUrl());
};

export const useIsHomepage = () => {
  const { linkBasePath } = useKsNav();
  const pathname = usePathname();
  return pathname === linkBasePath;
};

export const useIsPluginRoute = () => {
  const { createPluginPageUrl } = useKsNav();
  const pathname = usePathname();
  return pathname.startsWith(
    createPluginPageUrl({ pluginId: '', pagePath: '' }),
  );
};

/**
 * Allow getting back to the last page after navigating to a different section
 * */
export const useSetLastPath = ({
  section,
}: {
  section: 'design' | 'document' | 'develop';
}) => {
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  const pathname = usePathname();
  const search = useSearchParams();
  const path = pathname.split('/').slice(4).join('/'); // remove /site/:siteId/:instanceId
  const lastPath = `/${path}${search.size > 0 ? `?${search.toString()}` : ''}`; // include query params for pattern pages
  const key = `ks:${siteId}:${section}:last-path`;
  useEffect(() => {
    sessionStorage.setItem(key, lastPath);
  }, [key, lastPath]);
};

export const useGetLastPath = ({
  section,
}: {
  section: 'design' | 'document';
}) => {
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  return sessionStorage.getItem(`ks:${siteId}:${section}:last-path`);
};

/**
 * Get the #hash from the URL
 */
export const useUrlHash = () => {
  const params = useParams();
  const [hash, setHash] = useState('');

  useEffect(() => {
    const currentHash = window.location.hash.replace('#', '');
    setHash(currentHash);
  }, [params]);

  return hash;
};
