'use client';

import { useRef, useState } from 'react';
import {
  sendAppEvent,
  sendUiEvent,
  useAppCtxSelector,
  useUserRoleCanEditSection,
} from '@/core';
import { Avatar, Menu, MenuIcon, MenuItem, MenuText } from '@knapsack/toby';
import { useKsNav } from '@/utils/routes';
import SuperAdminControls from '@/core/super-admin-controls';
import { openUrlInTab } from '@/utils/url-utils';
import { isCypress } from '@/utils/constants';

export const AccountMenu = () => {
  // Grab user and site off of xstate context
  const ctx = useAppCtxSelector(
    ({ user, site }) => ({
      user,
      site,
    }),
    { isEqualityDeep: true },
  );

  const { createSettingsUrl, goToRoot, goTo, isSiteInstanceReady } = useKsNav();
  const canEditSettings = useUserRoleCanEditSection('SETTINGS');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Avatar
        size="small"
        alt={ctx.user?.info?.displayName}
        imgSrc={ctx.user?.info?.profilePic}
        onTrigger={() => setIsMenuOpen(!isMenuOpen)}
        ref={anchorRef}
        testId="account-menu--trigger"
        label="User account menu"
      />
      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        header={
          ctx.user?.isSuperAdmin && (
            <MenuItem
              onTrigger={() => {
                setIsMenuOpen(false);
                sendUiEvent([
                  {
                    type: 'modal.setContent',
                    modal: {
                      body: <SuperAdminControls />,
                      testId: 'super-admin-controls',
                      title: 'Super Admin Controls',
                    },
                  },
                  'modal.triggerOpen',
                ]);
              }}
            >
              <MenuIcon symbol="state-warning" />
              <MenuText>Super admin</MenuText>
            </MenuItem>
          )
        }
        footer={
          <MenuItem
            onTrigger={() => sendAppEvent('user.signOut')}
            testId="account-menu--logout"
          >
            <MenuText>Log out</MenuText>
          </MenuItem>
        }
        testId="account-menu"
      >
        {ctx.site?.meta.siteId && (
          <MenuItem
            testId="account-menu--invite"
            onTrigger={() => {
              setIsMenuOpen(false);
              import('@/domains/users/components/user-invite').then(
                ({ showUserInviteModal }) =>
                  showUserInviteModal({ testId: 'user-menu--invite' }),
              );
            }}
          >
            <MenuIcon symbol="send" />
            <MenuText>Invite someone</MenuText>
          </MenuItem>
        )}
        <MenuItem
          testId="account-menu--profile"
          onTrigger={() => {
            setIsMenuOpen(false);
            const settingsUrl = createSettingsUrl({ settingId: 'profile' });

            // If user is on root, stay on root
            if (!isSiteInstanceReady) {
              return goToRoot('/user');
            }

            /**
             * If a user can edit settings, they should see profile within admin settings
             * If a user cannot edit settings, they should see profile as a standalone page
             *
             * IMPORTANT: Cypress doesn't support opening in a new tab, so we always keep this in the same tab
             */
            if (canEditSettings) {
              isCypress() ? goTo(settingsUrl) : openUrlInTab(settingsUrl);
            } else {
              isCypress() ? goToRoot('/user') : openUrlInTab('/user');
            }
          }}
        >
          <MenuIcon symbol="user-circle" />
          <MenuText>Profile settings</MenuText>
        </MenuItem>
      </Menu>
    </>
  );
};
